var layout = function layout() {
  return import('layouts/common.vue');
}; //不作为Main组件的子页面展示的页面单独写，如下


var freeLayout = function freeLayout() {
  return import('layouts/free.vue');
};

var freeRouter = {
  path: '/',
  name: 'freeRouter',
  component: freeLayout,
  meta: {
    title: 'SCaaS2.0'
  },
  children: [{
    path: 'login',
    name: 'login',
    component: function component() {
      return import('pages/login.vue');
    }
  }, {
    path: 'dev/reactor',
    name: 'reactor',
    component: function component() {
      return import('pages/dev/reactor.vue');
    }
  }, {
    path: 'dev/excellent',
    name: 'excellent',
    component: function component() {
      return import('pages/dev/excellent.vue');
    }
  }, {
    path: 'dev/yamlLink',
    name: 'yamlLink',
    component: function component() {
      return import('pages/dev/yamlLink.vue');
    }
  }, {
    path: 'dev/tree',
    name: 'tree',
    component: function component() {
      return import('pages/dev/tree.vue');
    }
  }, {
    path: 'dev/savehtml',
    name: 'savehtml',
    component: function component() {
      return import('pages/dev/savehtml.vue');
    }
  }, {
    path: 'dev/nestonTable',
    name: 'nestonTable',
    component: function component() {
      return import('pages/dev/nestonTable.vue');
    }
  }]
}; //外部贞

var iframeLayout = function iframeLayout() {
  return import('pages/iframe/iframe.vue');
};

var iframe = {
  path: '/',
  name: 'iframe',
  component: iframeLayout,
  meta: {
    title: 'iframe'
  },
  children: [{
    path: 'inqiuryIframe',
    name: 'iframe_inqiuryIframe',
    component: function component() {
      return import('pages/iframe/inqiuryIframe.vue');
    }
  }, {
    path: 'contactUsIframe',
    name: 'iframe_contactUsIframe',
    component: function component() {
      return import('pages/iframe/contactUsIframe.vue');
    }
  }, {
    path: 'leaveIframe',
    name: 'iframe_leaveIframe',
    component: function component() {
      return import('pages/iframe/leaveIframe.vue');
    }
  }, {
    path: 'commentIframe',
    name: 'iframe_commentIframe',
    component: function component() {
      return import('pages/iframe/commentIframe.vue');
    }
  }, {
    path: 'lliffFooterIframe',
    name: 'iframe_lliffFooterIframe',
    component: function component() {
      return import('pages/iframe/lliffFooterIframe.vue');
    }
  }]
};
var page403 = {
  path: '/403',
  name: '403',
  component: function component() {
    return import('pages/403.vue');
  }
};

var soarDragonLayout = function soarDragonLayout() {
  return import('pages/booker/soarDragon/layout.vue');
};

var generalRouter = {
  path: '/booker/general',
  name: 'generalRouter',
  component: soarDragonLayout,
  children: [{
    path: 'book',
    name: 'generalBook',
    component: function component() {
      return import('pages/booker/general/generalBook/bookSpace.vue');
    }
  }, {
    path: 'generalList',
    name: 'generalList',
    component: function component() {
      return import('pages/booker/general/generalList/generalList.vue');
    }
  }, {
    path: 'generalDetail/:id',
    name: 'generalDetail',
    component: function component() {
      return import('pages/booker/general/generalDetail/generalDetail.vue');
    }
  }, {
    path: 'generalDetailSap/:id',
    name: 'generalDetailSap',
    component: function component() {
      return import('pages/booker/general/generalDetail/generalDetailSap.vue');
    }
  }]
}; //-wms仓储管理

var wmsRouter = {
  path: '/booker/wms',
  name: 'wmsRouter',
  component: soarDragonLayout,
  children: [{
    path: 'cargoDetailsList',
    name: 'cargoDetailsList',
    component: function component() {
      return import('pages/booker/wms/cargoDetails/list.vue');
    }
  }, {
    path: 'addDetails',
    name: 'addDetails',
    component: function component() {
      return import('pages/booker/wms/cargoDetails/addDetails.vue');
    }
  }]
}; //仓储系统

var storageRouter = {
  path: '/booker/storage',
  name: 'storageRouter',
  component: soarDragonLayout,
  children: [{
    path: 'cargo',
    name: 'cargo',
    component: function component() {
      return import('pages/booker/storage/cargo/list.vue');
    }
  }, {
    path: 'sku',
    name: 'sku',
    component: function component() {
      return import('pages/booker/storage/my_sku/list.vue');
    }
  }, {
    path: 'inbound',
    name: 'inbound',
    component: function component() {
      return import('pages/booker/storage/inbound/list.vue');
    }
  }, {
    path: 'inventory',
    name: 'inventory',
    component: function component() {
      return import('pages/booker/storage/inventory/list.vue');
    }
  }]
};
var soarDragonRouter = {
  path: '/booker/soarDragon',
  name: 'soarDragonRouter',
  component: soarDragonLayout,
  meta: {
    title: '吉吉供应链云平台'
  },
  children: [{
    path: '/booker/special_booking_amz',
    name: 'specialBookingAmz',
    redirect: function redirect(to) {
      return '/booker/soarDragon/inquiry';
    }
  }, {
    path: 'book',
    name: 'soarDragonBook',
    component: function component() {
      return import('pages/booker/soarDragon/book.vue');
    }
  }, {
    path: 'inquiry',
    name: 'soarDragonInquiry',
    component: function component() {
      return import('pages/booker/soarDragon/inquiry.vue');
    }
  }, {
    path: 'inquiryHistory',
    name: 'inquiryHistory',
    component: function component() {
      return import('pages/booker/soarDragon/query/inquiryHistory/index.vue');
    }
  }, {
    path: 'intro',
    name: 'soarDragonIntro',
    component: function component() {
      return import('pages/booker/soarDragon/intro.vue');
    }
  }, {
    path: 'ourService',
    name: 'OurService',
    component: function component() {
      return import('pages/booker/soarDragon/ourService.vue');
    }
  }, {
    path: 'faq',
    name: 'soarDragonFaq',
    component: function component() {
      return import('pages/booker/soarDragon/faq.vue');
    }
  }, {
    path: 'my',
    name: 'soarDragonMy',
    component: function component() {
      return import('pages/booker/soarDragon/my.vue');
    }
  }, {
    path: 'commonInfo',
    name: 'soarDragonCommonInfo',
    component: function component() {
      return import('pages/booker/soarDragon/commonInfo/commonInfo.vue');
    }
  }, {
    path: 'changePassword',
    name: 'soarDragonChangePassword',
    component: function component() {
      return import('pages/booker/soarDragon/changePassword.vue');
    }
  }, {
    path: 'verify',
    name: 'soarDragonVerify',
    component: function component() {
      return import('pages/booker/soarDragon/verifyRealname.vue');
    }
  }, {
    path: 'memberInfo',
    name: 'soarDragonMemberInfo',
    component: function component() {
      return import('pages/booker/soarDragon/memberInfo.vue');
    }
  }, {
    path: 'shipment',
    name: 'soarDragonShipment',
    component: function component() {
      return import('pages/booker/soarDragon/booking/shipment.vue');
    }
  }, {
    path: 'record',
    name: 'soarDragonRecord',
    component: function component() {
      return import('pages/booker/soarDragon/booking/record.vue');
    }
  }, {
    path: 'bill',
    name: 'soarDragonBill',
    component: function component() {
      return import('pages/booker/soarDragon/billManagement/index.vue');
    }
  }, {
    path: 'billDetails/:id',
    name: 'soarDragonBillDetails',
    component: function component() {
      return import('pages/booker/soarDragon/billManagement/bill/billDetails.vue');
    }
  }, {
    path: 'payMobile/:id/:currency',
    name: 'soarDragonPayMobile',
    component: function component() {
      return import('pages/booker/soarDragon/billManagement/payment/mobile/payMobile.vue');
    }
  }, {
    path: 'booking/shipmentDetail',
    name: 'soarDragonShipmentDetail',
    component: function component() {
      return import('pages/booker/soarDragon/booking/shipmentDetail.vue');
    }
  }, {
    path: 'booking/bookingEdit',
    name: 'soarDragonBookingEdit',
    component: function component() {
      return import('pages/booker/soarDragon/booking/bookingEdit.vue');
    }
  }, {
    path: 'booking/newBookingEdit',
    name: 'soarDragonNewBookingEdit',
    component: function component() {
      return import('pages/booker/soarDragon/booking/newBookingEdit.vue');
    }
  }, {
    path: 'joinCooperation',
    name: 'soarDragonJoinCooperation',
    component: function component() {
      return import('pages/booker/soarDragon/joinCooperation.vue');
    }
  }, {
    path: 'coupon',
    name: 'soarDragonCoupon',
    component: function component() {
      return import('pages/booker/soarDragon/coupon/Coupon.vue');
    }
  }, {
    path: 'receive_a_coupon',
    name: 'receive_a_coupon',
    component: function component() {
      return import('pages/booker/soarDragon/coupon/receiveCoupon.vue');
    }
  }, {
    path: 'feedback',
    name: 'soarDragonFeedback',
    component: function component() {
      return import('pages/booker/soarDragon/feedback/feedback.vue');
    }
  }, {
    path: 'finance',
    name: 'soarDragonFinance',
    component: function component() {
      return import('pages/booker/soarDragon/financeManage/invoice/index.vue');
    }
  }, {
    path: 'invoiceMake',
    name: 'soarDragonInvoiceMake',
    component: function component() {
      return import('pages/booker/soarDragon/financeManage/invoice/invoiceMake.vue');
    }
  }]
};

var recruitLayout = function recruitLayout() {
  return import('pages/company/recruit/layout.vue');
};

var recruitRouter = {
  path: '/company/recruit',
  name: 'recruitRouter',
  component: recruitLayout,
  meta: {
    title: '吉吉国际供应链公司招聘平台'
  },
  children: [{
    path: '/company/recruit/exam',
    name: 'company_recruit_exam',
    component: function component() {
      return import('pages/company/recruit/Exam.vue');
    }
  }]
}; // 作为layout组件的子页面展示但是不在左侧菜单显示的路由写在otherRouter里(children至少包含一个子路由)

var otherRouter = {
  path: '/',
  name: 'otherRouter',
  component: layout,
  children: [{
    path: 'home',
    name: 'home_index',
    component: function component() {
      return import('pages/index.vue');
    }
  }, {
    path: 'userinfo',
    name: 'userinfo',
    component: function component() {
      return import('pages/user/userinfo.vue');
    }
  }]
};

var frontpageLayout = function frontpageLayout() {
  return import('pages/frontpage/layout.vue');
};

var frontpageRouter = [{
  path: '/frontpage',
  name: 'Frontpage',
  component: frontpageLayout,
  children: [{
    path: 'index',
    name: 'frontpage_index',
    component: function component() {
      return import('pages/frontpage/index.vue');
    }
  }, {
    path: 'trace',
    name: 'frontpage_trace',
    component: function component() {
      return import('pages/frontpage/trace.vue');
    }
  }]
}]; // 作为layout组件的子页面展示并且在左侧菜单显示的路由写在appRouter里(children至少包含一个子路由)

var lliffLayout = function lliffLayout() {
  return import('pages/lliff/layout.vue');
};

var lliffRouter = [{
  path: '/lliff',
  name: 'Lliff',
  component: lliffLayout,
  children: [{
    path: 'index',
    name: 'lliff_index',
    component: function component() {
      return import('pages/lliff/index.vue');
    }
  }, {
    path: 'inquiry',
    name: 'lliff_inquiry',
    component: function component() {
      return import('pages/lliff/inquiry.vue');
    }
  }, {
    path: 'intro',
    name: 'lliff_intro',
    component: function component() {
      return import('pages/lliff/intro.vue');
    }
  }, {
    path: 'services',
    name: 'lliff_services',
    component: function component() {
      return import('pages/lliff/services.vue');
    }
  }, {
    path: 'elogistics',
    name: 'lliff_elogistics',
    component: function component() {
      return import('pages/lliff/elogistics.vue');
    }
  }, {
    path: 'fba',
    name: 'lliff_fba',
    component: function component() {
      return import('pages/lliff/fba.vue');
    }
  }, {
    path: 'case',
    name: 'lliff_case',
    component: function component() {
      return import('pages/lliff/case.vue');
    }
  }, {
    path: 'contacts',
    name: 'lliff_contacts',
    component: function component() {
      return import('pages/lliff/contacts.vue');
    }
  }, {
    path: 'solutions',
    name: 'lliff_solutions',
    component: function component() {
      return import('pages/lliff/lliffpage/solutions.vue');
    }
  }, {
    path: 'supplychaincloud',
    name: 'lliff_supplychaincloud',
    component: function component() {
      return import('pages/lliff/lliffpage/supplychaincloud.vue');
    }
  }, {
    path: 'wikiamazon',
    name: 'lliff_wikiamazon',
    component: function component() {
      return import('pages/lliff/lliffpage/wikiamazon.vue');
    }
  }, {
    path: 'aboutus',
    name: 'lliff_aboutus',
    component: function component() {
      return import('pages/lliff/lliffpage/aboutus.vue');
    }
  }, {
    path: 'contactsus',
    name: 'lliff_contactsus',
    component: function component() {
      return import('pages/lliff/lliffpage/contactsus.vue');
    }
  }, {
    path: 'trace',
    name: 'lliff_trace',
    component: function component() {
      return import('pages/lliff/lliffpage/trace.vue');
    }
  }, {
    path: 'newsdetails',
    name: 'lliff_newsdetails',
    component: function component() {
      return import('pages/lliff/lliffpage/newsdetails.vue');
    }
  }]
}, {
  path: '/llifflogin',
  name: 'Llifflogin',
  component: function component() {
    return import('pages/lliff/lliffpage/llifflogin.vue');
  }
}];
var userRouter = [{
  path: '/my',
  name: 'My',
  component: layout,
  children: [{
    path: 'dashboard',
    name: 'my_dashboard',
    component: function component() {
      return import('pages/index.vue');
    }
  }, {
    path: 'login',
    name: 'my_login',
    component: function component() {
      return import('pages/user/login.vue');
    }
  }]
}, {
  path: '/inquiry',
  name: 'Inquiry',
  component: layout,
  children: [{
    path: 'new',
    name: 'inquiry_new',
    component: function component() {
      return import('pages/inquiry/new.vue');
    }
  }]
}];
var appRouter = [{
  path: '/dashboard',
  name: 'Dashboard',
  component: layout,
  children: [{
    path: 'screen',
    name: 'Screen',
    component: function component() {
      return import('pages/dashboard/screen.vue');
    }
  }]
}, {
  path: '/logger',
  name: 'logger',
  component: layout,
  children: [{
    path: 'exception',
    name: 'admin_logger_exception',
    component: function component() {
      return import('pages/admin/logger/exception.vue');
    }
  }, {
    path: 'action',
    name: 'admin_logger_action',
    component: function component() {
      return import('pages/admin/logger/action.vue');
    }
  }]
}, {
  path: '/invoice',
  name: 'invoice',
  component: layout,
  children: [{
    path: 'invoice',
    name: 'admin_finance_invoice',
    component: function component() {
      return import('pages/admin/finance/invoice.vue');
    }
  }]
}, {
  path: '/finance',
  name: 'finance',
  component: layout,
  children: [{
    path: 'payment',
    name: 'admin_finance_payment',
    component: function component() {
      return import('pages/admin/finance/payment.vue');
    }
  }]
}, {
  path: '/finance',
  name: 'offlineConfirm',
  component: layout,
  children: [{
    path: 'offlineConfirm',
    name: 'admin_finance_offlineConfirm',
    component: function component() {
      return import('pages/admin/finance/offlineConfirm.vue');
    }
  }]
}, {
  path: '/comment',
  name: 'comment',
  component: layout,
  children: [{
    path: 'comment',
    name: 'comment',
    component: function component() {
      return import('pages/admin/comment/comment.vue');
    }
  }]
}, {
  path: '/wms',
  name: 'wms',
  component: layout,
  children: [{
    path: 'warehouse',
    name: 'admin_wms_warehouse',
    component: function component() {
      return import('pages/admin/wms/warehouse.vue');
    }
  }]
}, {
  path: '/wms',
  name: 'wms',
  component: layout,
  children: [{
    path: 'inbound',
    name: 'admin_wms_inbound',
    component: function component() {
      return import('pages/admin/wms/inbound.vue');
    }
  }]
}, {
  path: '/wms',
  name: 'wms',
  component: layout,
  children: [{
    path: 'inventory',
    name: 'admin_wms_inventory',
    component: function component() {
      return import('pages/admin/wms/inventory.vue');
    }
  }]
}, {
  path: '/file',
  name: 'file',
  component: layout,
  children: [{
    path: 'oss',
    name: 'admin_file_oss',
    component: function component() {
      return import('pages/admin/file/oss.vue');
    }
  }]
}, {
  path: '/workflow',
  name: 'workflow',
  component: layout,
  children: [{
    path: 'fsm',
    name: 'admin_workflow_fsm',
    component: function component() {
      return import('pages/admin/workflow/fsm.vue');
    }
  }, {
    path: 'form_template',
    name: 'admin_workflow_form_template',
    component: function component() {
      return import('pages/admin/workflow/form.vue');
    }
  }]
}, {
  path: '/mid_plat',
  name: 'mid_plat',
  component: layout,
  children: [{
    path: 'admin_global_config',
    name: 'admin_global_config',
    component: function component() {
      return import('pages/admin/global/config.vue');
    }
  }, {
    path: 'admin_global_content',
    name: 'admin_global_content',
    component: function component() {
      return import('pages/admin/global/content.vue');
    }
  }, {
    path: 'admin_global_document',
    name: 'admin_global_document',
    component: function component() {
      return import('pages/admin/global/document.vue');
    }
  }, {
    path: 'admin_global_lang_dic',
    name: 'admin_global_lang_dic',
    component: function component() {
      return import('pages/admin/global/langDic.vue');
    }
  }, {
    path: 'admin_global_page_part',
    name: 'admin_global_page_part',
    component: function component() {
      return import('pages/admin/global/pagePart.vue');
    }
  }, {
    path: 'admin_global_message_template',
    name: 'admin_global_message_template',
    component: function component() {
      return import('pages/admin/global/messageTemplate.vue');
    }
  }, {
    path: 'admin_global_help_center',
    name: 'admin_global_help_center',
    component: function component() {
      return import('pages/admin/global/helpCenter.vue');
    }
  }, {
    path: 'admin_global_table',
    name: 'admin_global_table',
    component: function component() {
      return import('pages/admin/global/table/index.vue');
    }
  }, {
    path: 'admin_global_text',
    name: 'admin_global_text',
    component: function component() {
      return import('pages/admin/global/text.vue');
    }
  }]
}, {
  path: '/admin_inquiry',
  name: 'admin_inquiry',
  component: layout,
  children: [{
    path: 'admin_inquiry_ipi_delivery',
    name: 'admin_inquiry_ipi_delivery',
    component: function component() {
      return import('pages/admin/inquiry/surcharge/ipi_delivery.vue');
    }
  }, {
    path: 'admin_inquiry_surcharge',
    name: 'admin_inquiry_surcharge',
    component: function component() {
      return import('pages/admin/inquiry/surcharge/surcharge.vue');
    }
  }, {
    path: 'admin_inquiry_surcharge_items',
    name: 'admin_inquiry_surcharge_items',
    component: function component() {
      return import('pages/admin/inquiry/surcharge/surcharge_items.vue');
    }
  }, {
    path: 'admin_inquiry_ipi',
    name: 'admin_inquiry_ipi',
    component: function component() {
      return import('pages/admin/inquiry/freight/ipi.vue');
    }
  }, {
    path: 'admin_inquiry_freight_air',
    name: 'admin_inquiry_freight_air',
    component: function component() {
      return import('pages/admin/inquiry/freight/air.vue');
    }
  }, {
    path: 'admin_inquiry_freight_exp',
    name: 'admin_inquiry_freight_exp',
    component: function component() {
      return import('pages/admin/inquiry/freight/exp.vue');
    }
  }, {
    path: 'admin_inquiry_freight_lcl',
    name: 'admin_inquiry_freight_lcl',
    component: function component() {
      return import('pages/admin/inquiry/freight/lcl.vue');
    }
  }, {
    path: 'admin_inquiry_discount',
    name: 'admin_inquiry_discount',
    component: function component() {
      return import('pages/admin/inquiry/discount.vue');
    }
  }, {
    path: 'admin_inquiry_newFreights',
    name: 'admin_inquiry_newFreights',
    component: function component() {
      return import('pages/admin/inquiry/newFreight/index.vue');
    }
  }, {
    path: 'general_inquiry_newFreights',
    name: 'general_inquiry_newFreights',
    component: function component() {
      return import('pages/admin/inquiry/generalNewFreight/index.vue');
    }
  }, {
    path: 'admin_inquiry_oldFreight',
    name: 'admin_inquiry_oldFreight',
    component: function component() {
      return import('pages/admin/inquiry/oldFreight/index.vue');
    }
  }, {
    path: 'admin_booker_booking',
    name: 'admin_booker_booking',
    component: function component() {
      return import('pages/admin/booker/booking.vue');
    }
  }, {
    path: 'admin_booker_booking',
    name: 'admin_booker_booking',
    component: function component() {
      return import('pages/admin/booker/booking.vue');
    }
  }, {
    path: 'admin_source_source',
    name: 'admin_source_source',
    component: function component() {
      return import('pages/admin/sourcer/source.vue');
    }
  }, {
    path: 'admin_consumer_js',
    name: 'admin_consumer_js',
    component: function component() {
      return import('pages/admin/consumer/js.vue');
    }
  }, {
    path: 'admin_inquiry_sales_channel',
    name: 'admin_inquiry_sales_channel',
    component: function component() {
      return import('pages/admin/coupon/coupon_get/index.vue');
    }
  }, {
    path: 'admin_inquiry_coupon_useage_log',
    name: 'admin_inquiry_coupon_useage_log',
    component: function component() {
      return import('pages/admin/coupon/useage_log/index.vue');
    }
  }, {
    path: 'admin_inquiry_sales_coupon',
    name: 'admin_inquiry_sales_coupon',
    component: function component() {
      return import('pages/admin/coupon/index.vue');
    }
  }]
}, {
  path: '/admin_inquiryAdmin',
  name: 'admin_inquiryAdmin',
  component: layout,
  children: [{
    path: 'admin_inquiryAdmin_incidentals_accessorial',
    name: 'admin_inquiryAdmin_incidentals_accessorial',
    component: function component() {
      return import('pages/admin/inquiry/incidentals/incidentalsAccessorial/incidentalsAccessorial.vue');
    }
  }, {
    path: 'admin_inquiryAdmin_incidentals_general',
    name: 'admin_inquiryAdmin_incidentals_general',
    component: function component() {
      return import('pages/admin/inquiry/incidentals/incidentalsGeneral/incidentalsGeneral.vue');
    }
  }, {
    path: 'admin_inquiryAdmin_incidentals_special',
    name: 'admin_inquiryAdmin_incidentals_special',
    component: function component() {
      return import('pages/admin/inquiry/incidentals/incidentalsSpecial/incidentalsSpecial.vue');
    }
  }, {
    path: 'admin_inquiryAdmin_incidentals_duty',
    name: 'admin_inquiryAdmin_incidentals_duty',
    component: function component() {
      return import('pages/admin/inquiry/incidentals/incidentalsDuty/incidentalsDuty.vue');
    }
  }, {
    path: 'general_inquiryAdmin_incidentals_accessorial',
    name: 'general_inquiryAdmin_incidentals_accessorial',
    component: function component() {
      return import('pages/admin/inquiry/generalIncidentals/generalIncidentalsAccessorial/generalIncidentalsAccessorial.vue');
    }
  }, {
    path: 'general_inquiryAdmin_incidentals_general',
    name: 'general_inquiryAdmin_incidentals_general',
    component: function component() {
      return import('pages/admin/inquiry/generalIncidentals/generalIncidentalsGeneral/generalIncidentalsGeneral.vue');
    }
  }, {
    path: 'general_inquiryAdmin_incidentals_special',
    name: 'general_inquiryAdmin_incidentals_special',
    component: function component() {
      return import('pages/admin/inquiry/generalIncidentals/generalIncidentalsSpecial/generalIncidentalsSpecial.vue');
    }
  }, {
    path: 'general_inquiryAdmin_incidentals_duty',
    name: 'general_inquiryAdmin_incidentals_duty',
    component: function component() {
      return import('pages/admin/inquiry/generalIncidentals/generalIncidentalsDuty/generalIncidentalsDuty.vue');
    }
  }, {
    path: 'general_country_city',
    name: 'general_country_city',
    component: function component() {
      return import('pages/admin/inquiry/countryCity/index.vue');
    }
  }, {
    path: 'admin_inquiryAdmin_warehouseText',
    name: 'admin_inquiryAdmin_warehouseText',
    component: function component() {
      return import('pages/admin/inquiry/warehouseText/warehouseText.vue');
    }
  }, {
    path: 'admin_inquiryAdmin_expenseSelect',
    name: 'admin_inquiryAdmin_expenseSelect',
    component: function component() {
      return import('pages/admin/inquiry/expenseSelect/expenseSelect.vue');
    }
  }, {
    path: 'admin_inquiryAdmin_inquiryHistory',
    name: 'admin_inquiryAdmin_inquiryHistory',
    component: function component() {
      return import('pages/admin/inquiry/inquiryHistory/index.vue');
    }
  }, {
    path: 'admin_inquiryAdmin_port',
    name: 'admin_inquiryAdmin_port',
    component: function component() {
      return import('pages/admin/inquiry/port/index.vue');
    }
  }, {
    path: 'admin_inquiryAdmin_warehouseAdmin',
    name: 'admin_inquiryAdmin_warehouseAdmin',
    component: function component() {
      return import('pages/admin/inquiry/warehouseAdmin/index.vue');
    }
  }]
}, {
  path: '/admin_dictionary',
  name: 'admin_dictionary',
  component: layout,
  children: [{
    path: 'admin_provider_seaport',
    name: 'admin_provider_seaport',
    component: function component() {
      return import('pages/admin/provider/seaport.vue');
    }
  }, {
    path: 'admin_provider_airport',
    name: 'admin_provider_airport',
    component: function component() {
      return import('pages/admin/provider/airport.vue');
    }
  }, {
    path: 'admin_provider_fba',
    name: 'admin_provider_fba',
    component: function component() {
      return import('pages/admin/provider/fba.vue');
    }
  }, {
    path: 'admin_provider_country',
    name: 'admin_provider_country',
    component: function component() {
      return import('pages/admin/provider/country.vue');
    }
  }, {
    path: 'admin_provider_city',
    name: 'admin_provider_city',
    component: function component() {
      return import('pages/admin/provider/city.vue');
    }
  }, {
    path: 'admin_provider_currency',
    name: 'admin_provider_currency',
    component: function component() {
      return import('pages/admin/provider/currency.vue');
    }
  }]
}, {
  path: '/system',
  name: 'system settings',
  component: layout,
  children: [{
    path: 'menu',
    name: 'menu',
    component: function component() {
      return import('pages/system/menu.vue');
    }
  }]
}, {
  path: '/permission',
  name: 'permission',
  component: layout,
  children: [{
    path: 'function',
    name: 'function',
    component: function component() {
      return import('pages/permission/function.vue');
    }
  }, {
    path: 'role',
    name: 'role',
    component: function component() {
      return import('pages/permission/role.vue');
    }
  }, {
    path: 'rolepermission',
    name: 'rolepermission',
    component: function component() {
      return import('pages/permission/rolepermission.vue');
    }
  }, {
    path: 'roleuser',
    name: 'roleuser' // component: () => import('pages/permission/roleuser.vue')

  }, {
    path: 'userrole',
    name: 'userrole' // component: () => import('pages/permission/userrole.vue')

  }]
}, {
  path: '/organization',
  name: 'organization',
  component: layout,
  children: [{
    path: 'department',
    name: 'department',
    component: function component() {
      return import('pages/organization/department.vue');
    }
  }, {
    path: 'position',
    name: 'position',
    component: function component() {
      return import('pages/organization/position.vue');
    }
  }]
}, {
  path: '/user',
  name: 'user',
  component: layout,
  children: [{
    path: 'index',
    name: 'user_index',
    component: function component() {
      return import('pages/admin/user/user.vue');
    }
  }, {
    path: 'verify_realname',
    name: 'verify_realname',
    component: function component() {
      return import('pages/admin/user/verifyRealname.vue');
    }
  }]
}, {
  path: '/company',
  name: 'company',
  component: layout,
  children: [{
    path: 'exam',
    name: 'admin_company_exam',
    component: function component() {
      return import('pages/admin/company/exam.vue');
    }
  }]
}, {
  path: '/business',
  name: 'business',
  component: layout,
  children: [{
    path: 'sku',
    name: 'sku',
    component: function component() {
      return import('pages/develop/business/sku.vue');
    }
  }]
}, {
  path: '/admin_booker',
  name: 'admin_booker',
  component: layout,
  children: [{
    path: 'admin_soar_dragon_booking',
    name: 'admin_soar_dragon_booking',
    component: function component() {
      return import('pages/admin/booker/soarDragonBooking.vue');
    }
  }]
}, {
  path: '/admin_booker',
  name: 'admin_booker',
  component: layout,
  children: [{
    path: 'admin_soar_dragon_booking2',
    name: 'admin_soar_dragon_booking2',
    component: function component() {
      return import('pages/admin/booker/soarDragonBooking2.vue');
    }
  }]
}, {
  path: '/admin_booker',
  name: 'admin_booker',
  component: layout,
  children: [{
    path: 'admin_soar_dragon_booking_group',
    name: 'admin_soar_dragon_booking_group',
    component: function component() {
      return import('pages/admin/booker/soarDragonBookingGroup/soarDragonBookingGroup.vue');
    }
  }]
}, {
  path: '/admin_booker',
  name: 'admin_booker',
  component: layout,
  children: [{
    path: 'admin_booking_common',
    name: 'admin_booking_common',
    component: function component() {
      return import('pages/admin/booker/bookingCommon/bookingCommon.vue');
    }
  }]
}]; // 所有上面定义的路由都要写在下面的routers里

export var routes = [freeRouter, page403, iframe, generalRouter, wmsRouter, storageRouter, soarDragonRouter, recruitRouter, otherRouter].concat(appRouter, userRouter, lliffRouter, frontpageRouter);